<template>
  <div class="container-platillos">
    <div class="container-head">
      <h3 class="mb-0">Platillos</h3>
      <p class="float-paso">Paso 3 de 3</p>
    </div>
    <button @click="mostrarAgregarPlatillo" class="btn-add white--text mb-6">
      <svg xmlns="http://www.w3.org/2000/svg" width="25.8" height="25.8">
        <g data-name="Grupo 51" transform="translate(-291.861 4.825)">
          <circle
            data-name="Elipse 15"
            class="color-add"
            cx="12.15"
            cy="12.15"
            r="12.15"
            transform="translate(292.611 -4.075)"
          />
          <path
            data-name="Línea 22"
            class="color-add"
            transform="translate(304.761 1.765)"
            d="M0 0v12.619"
          />
          <path
            data-name="Línea 23"
            class="color-add"
            transform="translate(298.451 8.075)"
            d="M0 0h12.619"
          />
        </g>
      </svg>
      Agregar platillos
    </button>
    <v-data-table :headers="headers" :items="platillos" sort-by="title">
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px">
            <v-form ref="form" @submit.prevent="save()" v-model="valid" lazy-validation>
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="6">
                        <v-text-field
                          v-model="editedItem.title"
                          :rules="nameRules"
                          label="Título"
                          color="primary"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="6">
                        <v-text-field
                          v-model="editedItem.precio"
                          :rules="numberRules"
                          color="primary"
                          label="Precio"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="6">
                        <v-text-field
                          v-model="editedItem.labelUnitario"
                          color="primary"
                          label="Etiqueta /Precio Unitario"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="6">
                        <v-text-field
                          v-model="editedItem.precioUnitario"
                          :rules="numberOpcionalRules"
                          color="primary"
                          label="Precio Unitario"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="editedItem.precioEspecial"
                          color="primary"
                          label="Precio Especial"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-textarea
                          label="Descripcion"
                          v-model="editedItem.descripcion"
                          no-resize
                          color="primary"
                          rows="1"
                        ></v-textarea>
                      </v-col>
                      <v-col cols="12">
                        <div
                          class="drag-inputFile mb-8"
                          @drop.stop.prevent="onDropImgPlatillo($event, editedItem)"
                          @dragover.prevent
                          @dragenter.prevent
                        >
                          <label for="file-img-platillo" class="select"></label>
                          <input
                            @change="changeImgPlatillo($event, editedItem)"
                            id="file-img-platillo"
                            class="input-file"
                            accept=".png, .jpg"
                            type="file"
                          />
                          <div
                            class="img-preview"
                            v-if="editedItem.urlImage"
                            v-bind:style="{ 'background-image': 'url(' + editedItem.urlImage + ')' }"
                          >
                          </div>
                          <v-icon small class="editBtn" v-if="editedItem.urlImage">mdi-pencil</v-icon>
                          <v-icon small class="deleteBtn" @click="openModalDeleteImage()" v-if="editedItem.urlImage">mdi-delete</v-icon>
                          <div class="upload">
                            <img
                              class="icon-camara"
                              :src="require('@/assets/camara.svg')"
                              alt="icon camara"
                            />
                            <p class="label-black">Seleccionar archivo</p>
                            <p class="label-instruccion">o arrastra una imagen aquí.</p>
                          </div>
                            <!-- modal eliminar -->
                            <v-row justify="center">
                              <v-dialog v-model="dialogDeleteImgPlatillo" max-width="500px">
                                <v-card>
                                  <v-card-title class="text-h5"
                                    >Estas seguro que deseas remover la imagen</v-card-title
                                  >
                                  <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="errorColor" text @click="closeDeleteModal()"
                                      >Cancelar</v-btn
                                    >
                                    <v-btn
                                      color="primary"
                                      text
                                      @click="removeImgPlatillo(editedItem)"
                                      >Confirmar</v-btn
                                    >
                                    <v-spacer></v-spacer>
                                  </v-card-actions>
                                </v-card>
                              </v-dialog>
                            </v-row>
                            <!-- modal eliminar -->
                        </div>
                      </v-col>
                      <v-col cols="12">
                        <v-select
                          :items="categorias"
                          :item-text="'title'"
                          :item-value="'id'"
                          v-on:change="changeCategoria(editedItem.idCategoria)"
                          v-model="editedItem.idCategoria"
                          :rules="categoriaRules"
                          name="categoria"
                          label="Selecciona una categoria"
                          color="primary"
                        ></v-select>
                      </v-col>
                      <v-col cols="12">
                        <v-select
                          v-if="filterSubsCategorias.length > 0"
                          :items="filterSubsCategorias"
                          :item-text="'title'"
                          :item-value="'id'"
                          v-model="editedItem.idSubcategoria"
                          name="subcategoria"
                          label="Selecciona una subcategoría"
                          color="primary"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="errorColor" text @click="close">Cancelar</v-btn>
                  <v-btn
                    type="submit"
                    color="primary"
                    text
                    :loading="loading"
                    :disabled="!valid"
                  >Guardar</v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">Estas seguro que deseas eliminar este platillo?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="errorColor" text @click="closeDelete">Cancelar</v-btn>
                <v-btn color="primary" text @click="deleteItemConfirm" :loading="loading">Confirmar</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- modal estatus platillo -->
          <v-row justify="center">
            <v-dialog v-model="dialogEstatus" persistent max-width="500px">
              <v-card>
                <v-card-title
                  class="text-h5"
                >Estas seguro que deseas {{nameEstatus}} {{itemEstatus.title}}?</v-card-title>
                <v-card-actions>
                  <v-btn color="errorColor" text @click="closeUpdateSwitch(itemEstatus)">Cancelar</v-btn>
                  <v-btn
                    color="primary"
                    text
                    @click="confirmSwitchPlatillo(itemEstatus)"
                    :loading="loadingSwitch"
                  >Confirmar</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="d-flex justify-end">
          <button type="button" @click="editItem(item)" class="mr-4">
          <img width="18px" :src="require('@/assets/icon-edit.svg')" alt="icon edit" />
          </button>
          <button type="button" @click="deleteItem(item)" class="mr-4">
            <img width="15.8px" :src="require('@/assets/icon-delete.svg')" alt="icon delete" />
          </button>
          <v-switch color="secondary" dense="" v-model="item.estatus" v-on:change="openDialogEstatus(item)"></v-switch>
        </div>
      </template>
      <template v-slot:no-data>
        <p>No hay platillos</p>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "Platillos",
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      valid: false,
      loading: false,
      dataPlatillos: [],
      filterSubsCategorias: [],
      dataSubCategorias: [],
      selectedCategory: "",
      loadingData: false,
      nameRules: [v => !!v || "El título es obligatorio"],
      categoriaRules: [v => !!v || "La categoría es obligatoria"],
      dialogEstatus: false,
      nameEstatus: false,
      itemEstatus: {},
      loadingSwitch: false,
      dialogDeleteImgPlatillo: false,
      itemEditImage: {},  
      numberRules: [
        v =>
          Number.isInteger(Number(v)) || "El precio debe ser un número entero",
        v => v > 0 || "El número es obligatorio"
      ],
      numberOpcionalRules: [
        v =>
          Number.isInteger(Number(v)) || "El precio debe ser un número entero"
      ],
      headers: [
        {
          text: "Título",
          align: "start",
          sortable: false,
          value: "title",
          width: "30%"
        },
        {
          text: "Descripción",
          value: "descripcion",
          sortable: false,
          width: "40%"
        },
        { text: "Precio", value: "precio", sortable: false, align: "center" },
        {
          text: "Acciones",
          value: "actions",
          sortable: false,
          width: "150px",
          align: "right"
        }
      ],
      editedIndex: -1,
      editedItem: {
        urlImage: "",
        imagen: null,
        title: "",
        descripcion: "",
        precio: 0,
        precioUnitario: 0,
        precioEspecial: "",
        labelUnitario: "",
        estatus: true,
        idCategoria: 0,
        idSubcategoria: 0
      },
      urlDefaultImage: require("@/assets/default.png"),
      defaultItem: {
        urlImage: "",
        imagen: null,
        title: "",
        descripcion: "",
        precio: 0,
        precioUnitario: 0,
        precioEspecial: "",
        labelUnitario: "",
        estatus: true,
        idCategoria: 0,
        idSubcategoria: 0
      }
    };
  },
  computed: {
    ...mapState([
      "platillos",
      "categorias",
      "subcategorias",
      "restaurantCurrent"
    ]),
    formTitle() {
      return this.editedIndex === -1 ? "Agregar platillo" : "Editar platillo";
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    }
  },
  methods: {
    ...mapActions(["creaPlatillo", "actualizaPlatillo", "desactivaPlatillo", "eliminaPlatillo"]),
    //abre modal abrir platillo
    mostrarAgregarPlatillo() {
      this.dialog = true;
    },
    //evento drop input file
    onDropImgPlatillo(event, editedItem) {
      const file = event.dataTransfer.files[0];
      this.processImagePlatillo(file, editedItem);
    },
    //evento cuando cambia el archivo
    changeImgPlatillo(event, editedItem) {
      const file = event.target.files[0];
      this.processImagePlatillo(file, editedItem);
    },
    //evento que valida la imagen que se ejecuta con los eventos change, drop
    processImagePlatillo(file, editedItem) {
      var ext = file.name.split(".").pop();
      if (ext == "png" || ext == "jpg") {
        if (file.size <= 512017) {
          if (file) {
            editedItem.imagen = file;
            this.imageProcessPlatillos(file, editedItem);
          }
        } else {
          this.$swal({
            showConfirmButton: false,
            timer: 3000,
            icon: "error",
            timerProgressBar: true,
            title: "El tamaño maximo permitido es de 500kb"
          });
        }
      } else {
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
          timerProgressBar: true,
          title: "Solo se permiten archivos de tipo .png, .jpg"
        });
      }
    },
    //funcion que asigna la imagen que se carga
    imageProcessPlatillos(file, editedItem) {
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = e => {
          editedItem.urlImage = e.target.result;
        };
      } else {
        editedItem.urlImage = this.urlDefaultImage;
      }
    },
    removeImgPlatillo(editedItem){
      editedItem.imagen = null;
      editedItem.urlImage = "";
      this.closeDeleteModal();
    },
    //asigna el elemento a editar
    editItem(item) {
      this.editedIndex = this.platillos.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
      this.changeCategoria(this.editedItem.idCategoria);
    },
    //asigna el elemento a eliminar
    deleteItem(item) {
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    //evento que se ejecuta cuando se confirma la eliminacion del platillo
    async deleteItemConfirm() {
      this.loading = true;
      this.editedItem.idRestaurante = this.restaurantCurrent.id;
      const result = await this.eliminaPlatillo(this.editedItem);
      if (result.res) {
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "success",
          timerProgressBar: true,
          title: "Se eliminó correctamente"
        });
      } else {
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
          timerProgressBar: true,
          title: result.error
        });
      }
      this.loading = false;
      this.closeDelete();
    },
    openDialogEstatus(item) {
      this.dialogEstatus = true;
      this.nameEstatus = item.estatus ? "activar" : "desactivar";
      this.itemEstatus = item;
    },
    closeUpdateSwitch(item) {
      this.dialogEstatus = false;
      item.estatus = !item.estatus;
      this.itemEstatus = {};
    },
    async confirmSwitchPlatillo(item) {
      this.loadingSwitch = true;
      const platillObject = {
        id: item.id,
        idRestaurante: this.restaurantCurrent.id,
        estatus: item.estatus
      };
      const respuesta = item.estatus ? "activo" : "desactivó";
      const result = await this.desactivaPlatillo(platillObject);
      if (result.res) {
        this.dialogEstatus = false;
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "success",
          timerProgressBar: true,
          title: "Se " + respuesta + " correctamente."
        });
      } else {
        this.dialogEstatus = false;
        item.estatus = !item.estatus;
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
          timerProgressBar: true,
          title: result.error
        });
      }
      this.loadingSwitch = false;
    },
    //resetea la modal
    close() {
      this.resetValidation();
      this.dialog = false;
      this.filterSubsCategorias = [];
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      //se validan los campos
      if (!this.$refs.form.validate()) return;
      if (this.editedIndex > -1) {
        this.updatePlatillo();
      } else {
        this.savePlatillo();
      }
    },
    changeCategoria(id) {
      this.filterSubsCategorias = this.subcategorias.filter(
        item => item.idCategoria == id
      );
    },
    async updatePlatillo() {
      this.loading = true
      this.editedItem.idRestaurante = this.restaurantCurrent.id
      const result = await this.actualizaPlatillo(this.editedItem)
      if (result.res) {
        this.editedItem.imagen = null
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "success",
          timerProgressBar: true,
          title: "Se actualizó correctamente"
        });
      } else {
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
          timerProgressBar: true,
          title: result.error
        });
      }
      this.loading = false;
      this.close();
    },
    async savePlatillo() {
      this.loading = true;
      this.editedItem.idRestaurante = this.restaurantCurrent.id;
      const result = await this.creaPlatillo(this.editedItem);
      if (result.res) {
        this.editedItem.imagen = null
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "success",
          timerProgressBar: true,
          title: "Se agregó correctamente"
        });
      } else {
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
          timerProgressBar: true,
          title: result.error
        });
      }
      this.loading = false;
      this.close();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    openModalDeleteImage() {
      this.dialogDeleteImgPlatillo = true;
    },
    closeDeleteModal() {
      this.dialogDeleteImgPlatillo = false;
    },
  }
};
</script>
<style lang="scss">
.container-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  .float-paso {
    color: #a7a7a7;
    margin-bottom: 0;
    font-weight: normal;
  }
}
</style>