<template>
  <div class="datos-generales mb-12">
    <div class="header-detail">
      <h2 class="mb-0">{{ restaurantCurrent.nombre }}</h2>
      <div>
        <router-link class="btn-back" :to="{ name: 'Restaurantes' }">
          <img
            class="icon-arrow-left"
            :src="require('@/assets/arrow-left.svg')"
            alt="icon arrow-left"
          />
          Regresar
        </router-link>
        <v-btn
          color="buttonPrimary"
          class="white--text pr-8 pl-8 btn-margin"
          v-if="restaurantCurrent.slug"
          target="_blank"
          :to="{ name: 'Menu', params: { id: restaurantCurrent.slug } }"
          >Ver Menú</v-btn
        >
      </div>
      <p class="float-paso">Paso 1 de 3</p>
    </div>
    <div class="button-generate mb-8">
        <v-btn
          color="buttonPrimary"
          class="white--text pr-8 pl-8 btn-margin"
          @click="openGenerateQR(restaurantCurrent.slug)"
          >Generar QR</v-btn
        >
        <!-- modal QR -->
          <v-row justify="center">
            <v-dialog v-model="openModalQR" max-width="500px">
              <v-card>
                <v-card-title class="text-h5"
                  >Descargar el QR</v-card-title
                >
                <v-card-text>
                  <v-container>
                    <div class="d-flex-align-center">
                    <v-row>
                      <v-col cols="12" sm="12" md="12" lg="12">
                        <div class="d-flex justify-center">
                          <vue-qr qid="imgQR" :callback="generaQR" :text="urlCurrentMenu" :size="200"></vue-qr>
                        </div>
                      </v-col>
                    </v-row>
                     </div>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="errorColor" text @click="closeModalQR()"
                    >Cancelar</v-btn
                  >
                  <v-btn color="primary" text @click="descargarQR()"
                    >Descargar</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
    </div>
    <v-form
      ref="form"
      v-model="valid"
      @submit.prevent="modificaDataRestaurante()"
      lazy-validation
      class="mb-6"
    >
      <v-row class="mb-8">
        <v-col col="12" sm="12" md="6">
          <v-text-field
            color="primary"
            class="mb-4"
            v-model="restaurantCurrent.nombre"
            :rules="nameRules"
            label="Nombre"
            disabled
            required
          ></v-text-field>
          <v-text-field
            color="primary"
            class="mb-4"
            v-model="restaurantCurrent.slug"
            disabled
            label="Slug"
          ></v-text-field>
          <p class="primary--text font-weight-bold">Logo del restaurante</p>
          <p>Cambiar imagen</p>
          <div
            class="drag-inputFile mb-8"
            @drop.stop.prevent="onDropFileLogo($event)"
            @dragover.prevent
            @dragenter.prevent
          >
            <label for="input-file" class="select"></label>
            <input
              @change="changeFileLogo($event)"
              id="input-file"
              class="input-file"
              accept=".png, .jpg"
              type="file"
            />
            <div
              class="img-preview"
              v-if="restaurantCurrent.foto"
              v-bind:style="{
                'background-image': 'url(' + restaurantCurrent.foto + ')',
              }"
            ></div>
            <v-icon small class="editBtn" v-if="restaurantCurrent.foto"
              >mdi-pencil</v-icon
            >
            <v-icon
              small
              class="deleteBtn"
              @click="openModalDeleteLogo()"
              v-if="restaurantCurrent.foto"
              >mdi-delete</v-icon
            >
            <div class="upload">
              <img
                class="icon-camara"
                :src="require('@/assets/camara.svg')"
                alt="icon camara"
              />
              <p class="label-black">Seleccionar archivo</p>
              <p class="label-instruccion">o arrastra una imagen aquí.</p>
            </div>
          </div>
          <!-- modal eliminar -->
          <v-row justify="center">
            <v-dialog v-model="dialogDeleteImagenLogo" max-width="500px">
              <v-card>
                <v-card-title class="text-h5"
                  >Estas seguro que deseas remover el logo</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="errorColor" text @click="closeModalDeleteLogo()"
                    >Cancelar</v-btn
                  >
                  <v-btn color="primary" text @click="removeFileLogo()"
                    >Confirmar</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
          <!-- modal eliminar -->
          <p class="primary--text font-weight-bold">Imagen principal</p>
          <p>Cambiar imagen</p>
          <div
            class="drag-inputFile mb-8"
            @drop.stop.prevent="onDropFileImgPrincipal($event)"
            @dragover.prevent
            @dragenter.prevent
          >
            <label for="file-img-principal" class="select"></label>
            <input
              @change="changeFileImgPrincipal($event)"
              id="file-img-principal"
              class="input-file"
              accept=".png, .jpg"
              type="file"
            />
            <div
              class="img-preview"
              v-if="restaurantCurrent.imgPrincipal"
              v-bind:style="{
                'background-image':
                  'url(' + restaurantCurrent.imgPrincipal + ')',
              }"
            ></div>
            <v-icon small class="editBtn" v-if="restaurantCurrent.imgPrincipal"
              >mdi-pencil</v-icon
            >
            <v-icon
              small
              class="deleteBtn"
              @click="openModalDeleteImgPrincipal()"
              v-if="restaurantCurrent.imgPrincipal"
              >mdi-delete</v-icon
            >
            <div class="upload">
              <img
                class="icon-camara"
                :src="require('@/assets/camara.svg')"
                alt="icon camara"
              />
              <p class="label-black">Seleccionar archivo</p>
              <p class="label-instruccion">o arrastra una imagen aquí.</p>
            </div>
          </div>
          <!-- modal eliminar -->
          <v-row justify="center">
            <v-dialog v-model="dialogDeleteImagenPrincipal" max-width="500px">
              <v-card>
                <v-card-title class="text-h5"
                  >Estas seguro que deseas remover la imagen principal</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="errorColor" text @click="closeModalDeleteImgPrincipal()"
                    >Cancelar</v-btn
                  >
                  <v-btn color="primary" text @click="removeImgPrincipal()"
                    >Confirmar</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
          <!-- modal eliminar -->
          <p class="primary--text font-weight-bold mb-0">Fondo</p>
          <v-select
            :items="opcionesFondo"
            v-model="restaurantCurrent.tipoFondo"
            color="primary"
            label="Elige una opción"
          ></v-select>
          <div v-show="restaurantCurrent.tipoFondo == 'imagen'">
            <div
              class="drag-inputFile mb-8"
              @drop.stop.prevent="onDropFileFondo($event)"
              @dragover.prevent
              @dragenter.prevent
            >
              <label for="file-img-fondo" class="select"></label>
              <input
                @change="changeFileFondo($event)"
                id="file-img-fondo"
                class="input-file"
                accept=".png, .jpg"
                type="file"
              />
              <div
                class="img-preview"
                v-if="restaurantCurrent.fondoImg"
                v-bind:style="{
                  'background-image': 'url(' + restaurantCurrent.fondoImg + ')',
                }"
              ></div>
              <v-icon small class="editBtn" v-if="restaurantCurrent.fondoImg"
                >mdi-pencil</v-icon
              >
              <v-icon
                small
                class="deleteBtn"
                @click="openModalDeleteImagen()"
                v-if="restaurantCurrent.fondoImg"
                >mdi-delete</v-icon
              >
              <div class="upload">
                <img
                  class="icon-camara"
                  :src="require('@/assets/camara.svg')"
                  alt="icon camara"
                />
                <p class="label-black">Seleccionar archivo</p>
                <p class="label-instruccion">o arrastra una imagen aquí.</p>
              </div>
              <!-- modal eliminar -->
              <v-row justify="center">
                <v-dialog v-model="dialogDeleteImagen" max-width="500px">
                  <v-card>
                    <v-card-title class="text-h5"
                      >Estas seguro que deseas remover la imagen de
                      fondo</v-card-title
                    >
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="errorColor"
                        text
                        @click="closeModalDeleteImagen()"
                        >Cancelar</v-btn
                      >
                      <v-btn color="primary" text @click="removeImgFondo()"
                        >Confirmar</v-btn
                      >
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-row>
              <!-- modal eliminar -->
            </div>
          </div>
          <v-color-picker
            v-show="restaurantCurrent.tipoFondo == 'color'"
            dot-size="20"
            hide-mode-switch
            mode="hexa"
            swatches-max-height="200"
            v-model="restaurantCurrent.colorFondo"
            class="mb-4"
          ></v-color-picker>
          <p class="primary--text font-weight-bold">
            Color de la barra de navegación
          </p>
          <v-color-picker
            dot-size="20"
            hide-mode-switch
            mode="hexa"
            swatches-max-height="200"
            v-model="restaurantCurrent.colorBar"
          ></v-color-picker>
          <p class="primary--text font-weight-bold">Color de texto principal</p>
          <v-color-picker
            dot-size="20"
            hide-mode-switch
            mode="hexa"
            swatches-max-height="200"
            v-model="restaurantCurrent.colorTextoPrincipal"
          ></v-color-picker>
        </v-col>
      </v-row>
      <v-btn
        color="buttonPrimary"
        class="white--text pr-8 pl-8 btn-margin"
        :loading="loading"
        type="submit"
        >Guardar cambios</v-btn
      >
    </v-form>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import VueQr from 'vue-qr'

export default {
  data() {
    return {
      valid: false,
      loading: false,
      nameRules: [(v) => !!v || "El nombre es requerido"],
      imgDefault: require("@/assets/default.png"),
      opcionesFondo: ["color", "imagen"],
      dialogDeleteImagen: false,
      dialogDeleteImagenLogo: false,
      dialogDeleteImagenPrincipal: false,
      openModalQR : false,
      urlCurrentMenu: "",
      size: 300,
      urlImgQR : "",
      urlCanvasMe: ""
    };
  },
  components:{
    VueQr
  },
  computed: {
    ...mapState(["restaurantCurrent"]),
  },
  methods: {
    ...mapActions(["actualizaRestaurante"]),
    openGenerateQR(slugParam){
      this.openModalQR = true;
      this.urlCurrentMenu = "https://tumenu.mx/menu/"+slugParam;
    },
    closeModalQR(){
      this.openModalQR = false;
    },
    generaQR(dataUrl){
      this.urlImgQR = dataUrl;
    },
    descargarQR(){
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const image = new Image;
      image.src = this.urlImgQR;
      ctx.canvas.width = 200;
      ctx.canvas.height = 220;
      ctx.drawImage(image, 0, 0, 200, 200);
      ctx.font = "12px Arial";
      ctx.textAlign = "center";
      ctx.fillText("Realizado por tumenu.mx", 200 / 2, 210); 
      let urlCanvas = canvas.toDataURL();
      var a = document.createElement('a');
      a.href = urlCanvas;
      a.download = "qrtumenu.png";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    async modificaDataRestaurante() {
      this.loading = true;
      const result = await this.actualizaRestaurante(this.restaurantCurrent);
      if (result.res) {
        if (result.foto) this.restaurantCurrent.foto = result.foto;
        if (result.imgPrincipal)
          this.restaurantCurrent.imgPrincipal = result.imgPrincipal;
        if (result.fondoImg) this.restaurantCurrent.fondoImg = result.fondoImg;
        this.limpiaImagesUpload();
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "success",
          timerProgressBar: true,
          title: "Se actualizó correctamente",
        });
      } else {
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
          timerProgressBar: true,
          title: result.error,
        });
      }
      this.loading = false;
    },
    limpiaImagesUpload() {
      this.restaurantCurrent.imagenLogo = null;
      this.restaurantCurrent.imagenPricipal = null;
      this.restaurantCurrent.imagenFondo = null;
    },
    nombreRestaurante() {
      if (this.restaurantCurrent.nombre == null) return;
      var slug = this.restaurantCurrent.nombre
        .toLowerCase()
        .replace(/[^\w ]+/g, "")
        .replace(/ +/g, "-");
      this.restaurantCurrent.slug = slug;
    },
    onDropFileLogo(event) {
      const file = event.dataTransfer.files[0];
      this.processFileLogo(file);
    },
    changeFileLogo(event) {
      const file = event.target.files[0];
      this.processFileLogo(file);
    },
    processFileLogo(file) {
      var ext = file.name.split(".").pop();
      if (ext == "png" || ext == "jpg") {
        if (file.size <= 512017) {
          if (file) {
            this.restaurantCurrent.imagenLogo = file;
            this.imageProcess(file);
          }
        } else {
          this.$swal({
            showConfirmButton: false,
            timer: 3000,
            icon: "error",
            timerProgressBar: true,
            title: "El tamaño maximo permitido es de 500kb",
          });
        }
      } else {
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
          timerProgressBar: true,
          title: "Solo se permiten archivos de tipo .png, .jpg",
        });
      }
    },
    imageProcess(file) {
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          this.restaurantCurrent.foto = e.target.result;
        };
      } else {
        this.restaurantCurrent.foto = require("@/assets/default.png");
      }
    },
    removeFileLogo(file) {
      this.restaurantCurrent.imagenLogo = null;
      this.restaurantCurrent.foto = "";
      this.closeModalDeleteLogo();
    },
    //funciones para img principal
    onDropFileImgPrincipal(event) {
      const file = event.dataTransfer.files[0];
      this.processFileImgPrincipal(file);
    },
    changeFileImgPrincipal(event) {
      const file = event.target.files[0];
      this.processFileImgPrincipal(file);
    },
    processFileImgPrincipal(file) {
      var ext = file.name.split(".").pop();
      if (ext == "png" || ext == "jpg") {
        if (file.size <= 512017) {
          if (file) {
            this.restaurantCurrent.imagenPricipal = file;
            this.imageProcessImgPrincipal(file);
          }
        } else {
          this.$swal({
            showConfirmButton: false,
            timer: 3000,
            icon: "error",
            timerProgressBar: true,
            title: "El tamaño maximo permitido es de 500kb",
          });
        }
      } else {
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
          timerProgressBar: true,
          title: "Solo se permiten archivos de tipo .png, .jpg",
        });
      }
    },
    imageProcessImgPrincipal(file) {
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          this.restaurantCurrent.imgPrincipal = e.target.result;
        };
      } else {
        this.restaurantCurrent.imgPrincipal = require("@/assets/default.png");
      }
    },
    removeImgPrincipal(file) {
      this.restaurantCurrent.imagenPricipal = null;
      this.restaurantCurrent.imgPrincipal = "";
      this.closeModalDeleteImgPrincipal();
    },
    onDropFileFondo(event) {
      const file = event.dataTransfer.files[0];
      this.processFileFondo(file);
    },
    changeFileFondo(event) {
      const file = event.target.files[0];
      this.processFileFondo(file);
    },
    processFileFondo(file) {
      var ext = file.name.split(".").pop();
      if (ext == "png" || ext == "jpg") {
        if (file.size <= 512017) {
          if (file) {
            this.restaurantCurrent.imagenFondo = file;
            this.imageProcessFondo(file);
          }
        } else {
          this.$swal({
            showConfirmButton: false,
            timer: 3000,
            icon: "error",
            timerProgressBar: true,
            title: "El tamaño maximo permitido es de 500kb",
          });
        }
      } else {
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
          timerProgressBar: true,
          title: "Solo se permiten archivos de tipo .png, .jpg",
        });
      }
    },
    imageProcessFondo(file) {
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          this.restaurantCurrent.fondoImg = e.target.result;
        };
      } else {
        this.restaurantCurrent.fondoImg = require("@/assets/default.png");
      }
    },
    removeImgFondo(file) {
      this.restaurantCurrent.imagenFondo = null;
      this.restaurantCurrent.fondoImg = "";
      this.closeModalDeleteImagen();
    },
    openModalDeleteImagen() {
      this.dialogDeleteImagen = true;
    },
    closeModalDeleteImagen() {
      this.dialogDeleteImagen = false;
    },
    openModalDeleteLogo() {
      this.dialogDeleteImagenLogo = true;
    },
    closeModalDeleteLogo() {
      this.dialogDeleteImagenLogo = false;
    },
    openModalDeleteImgPrincipal() {
      this.dialogDeleteImagenPrincipal = true;
    },
    closeModalDeleteImgPrincipal() {
      this.dialogDeleteImagenPrincipal = false;
    },
  },
};
</script>
<style lang="scss">
.header-detail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-bottom: 16px;
  .btn-back {
    margin-right: 35px;
  }
  .float-paso {
    color: #a7a7a7;
    position: absolute;
    right: 0;
    bottom: -60px;
    margin-bottom: 0;
    font-weight: normal;
  }
}
</style>