<template>
  <v-expansion-panels class="mt-8 mb-12">
    <v-expansion-panel dense v-for="(item, index) in categorias" :key="index">
      <v-expansion-panel-header color="headerTable" class="py-6">
        <p class="mb-0">{{ item.title }}</p>
      </v-expansion-panel-header>
      <v-expansion-panel-content color="transparent">
        <div class="py-8">
          <div class="container-switch">
            <div class="d-flex justify-end">
              <v-switch
                color="secondary"
                label="Activo"
                v-model="item.estatus"
                v-on:change="openDialogEstatus(item)"
              ></v-switch>
            </div>
            <!-- modal estatus categoria -->
            <v-row justify="center">
              <v-dialog v-model="item.dialog" persistent max-width="500px">
                <v-card>
                  <v-card-title class="text-h5"
                    >Estas seguro que deseas {{ nameEstatus }}
                    {{ item.title }}?</v-card-title
                  >
                  <v-card-actions>
                    <v-btn
                      color="errorColor"
                      text
                      @click="cancelarActualizar(item)"
                      >Cancelar</v-btn
                    >
                    <v-btn
                      color="primary"
                      text
                      @click="confirmSwitchCategoria(item)"
                      :loading="loadingSwitch"
                      >Confirmar</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>
          </div>
          <v-form class="mb-4" v-model="valid">
            <v-row>
              <v-col col="12" sm="12" md="6">
                <v-text-field
                  color="primary"
                  class="mb-4"
                  :rules="nameRules"
                  v-model="item.title"
                  label="Titulo"
                  required
                ></v-text-field>
                <p class="primary--text font-weight-bold">Imagen categoría</p>
                <p>Cambiar imagen</p>
                <div
                  class="drag-inputFile mb-8"
                  @drop.stop.prevent="onDropImgCategoryUpdate($event, item)"
                  @dragover.prevent
                  @dragenter.prevent
                >
                  <label
                    :for="'file-update-category' + index"
                    class="select"
                  ></label>
                  <input
                    @change="changeImgCategoryUpdate($event, item)"
                    :id="'file-update-category' + index"
                    class="file-update-category"
                    accept=".png, .jpg"
                    type="file"
                  />
                  <div
                    class="img-preview"
                    v-if="item.urlImage"
                    v-bind:style="{
                      'background-image': 'url(' + item.urlImage + ')',
                    }"
                  ></div>
                  <v-icon small class="editBtn" v-if="item.urlImage"
                    >mdi-pencil</v-icon
                  >
                  <v-icon
                    small
                    class="deleteBtn"
                    @click="removeImgCategoria(item)"
                    v-if="item.urlImage"
                    >mdi-delete</v-icon
                  >
                  <div class="upload">
                    <img
                      class="icon-camara"
                      :src="require('../assets/camara.svg')"
                      alt="icon camara"
                    />
                    <p class="label-black">Seleccionar archivo</p>
                    <p class="label-instruccion">o arrastra una imagen aquí.</p>
                  </div>
                </div>
                <p class="primary--text font-weight-bold">
                  Color de fondo de contenedor
                </p>
                <v-color-picker
                  dot-size="20"
                  hide-mode-switch
                  mode="hexa"
                  swatches-max-height="200"
                  v-model="item.colorCaja"
                ></v-color-picker>
                <p class="primary--text font-weight-bold">Color de texto</p>
                <v-color-picker
                  dot-size="20"
                  hide-mode-switch
                  mode="hexa"
                  swatches-max-height="200"
                  v-model="item.color"
                ></v-color-picker>
                <v-textarea
                  label="Comentarios"
                  no-resize
                  rows="4"
                  :value="item.comentarios"
                  v-model="item.comentarios"
                ></v-textarea>
              </v-col>
              <v-col col="12" sm="12">
                <AgregarSubcategorias
                  :idCategoria="item.id"
                  :idRest="idRestaurant"
                ></AgregarSubcategorias>
              </v-col>
              <v-col col="12" sm="12">
                <v-btn
                  color="buttonPrimary"
                  class="white--text btn-margin mr-6 pr-8 pl-8"
                  @click="updateCategoria(item)"
                  :disabled="!valid"
                  :loading="loading"
                  >Guardar cambios</v-btn
                >
                <v-btn
                  color="errorColor"
                  class="white--text pr-8 pl-8"
                  @click="
                    openEliminarCategoria(item.title, item.id, item.urlImage)
                  "
                  >Eliminar</v-btn
                >
              </v-col>
              <!-- modal eliminar categoria -->
              <v-row justify="center">
                <v-dialog v-model="dialogDelete" max-width="500px">
                  <v-card>
                    <v-card-title class="text-h5"
                      >Estas seguro que deseas eliminar
                      {{ nameDeleteCategoria }}?</v-card-title
                    >
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="errorColor" text @click="closeDeleteModalCategoria()"
                        >Cancelar</v-btn
                      >
                      <v-btn
                        color="primary"
                        text
                        @click="deleteCategoria()"
                        :loading="loadingDelete"
                        >Confirmar</v-btn
                      >
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-row>
              <!-- modal eliminar categoria -->
            </v-row>
          </v-form>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import { mapActions, mapState } from "vuex";
import AgregarSubcategorias from "@/components/AgregarSubcategorias";
export default {
  props: {
    categorias: Array,
    idRestaurant: String,
  },
  data() {
    return {
      valid: false,
      nameRules: [(v) => !!v || "El título es requerido"],
      loading: false,
      loadingDelete: false,
      nameEstatus: "",
      loadingSwitch: false,
      dialogDelete: false,
      nameDeleteCategoria: "",
      itemEliminarCategoria: {},
    };
  },
  components: {
    AgregarSubcategorias,
  },
  methods: {
    ...mapActions([
      "actualizaCategoria",
      "desactivarCategoria",
      "eliminaCategoria",
    ]),
    onDropImgCategoryUpdate(event, item) {
      const file = event.dataTransfer.files[0];
      this.processImgCategoryUpdate(file, item);
    },
    changeImgCategoryUpdate(event, item) {
      const file = event.target.files[0];
      this.processImgCategoryUpdate(file, item);
    },
    processImgCategoryUpdate(file, item) {
      var ext = file.name.split(".").pop();
      if (ext == "png" || ext == "jpg") {
        if (file.size <= 512017) {
          if (file) {
            item.imagen = file;
            this.imageProcessUpdateCategory(file, item);
          }
        } else {
          this.$swal({
            showConfirmButton: false,
            timer: 3000,
            icon: "error",
            timerProgressBar: true,
            title: "El tamaño maximo permitido es de 500kb",
          });
        }
      } else {
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
          timerProgressBar: true,
          title: "Solo se permiten archivos de tipo .png, .jpg",
        });
      }
    },
    imageProcessUpdateCategory(file, item) {
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          item.urlImage = e.target.result;
        };
      } else {
        item.urlImage = require("../assets/default.png");
      }
    },
    removeImgCategoria(item) {
      item.imagen = null;
      item.urlImage = "";
    },
    async updateCategoria(item) {
      let categoriaUpdate = {
        urlImage: item.urlImage,
        imagen: item.imagen,
        id: item.id,
        title: item.title,
        color: item.color,
        colorCaja: item.colorCaja,
        comentarios: item.comentarios,
      };
      this.loading = true;
      const result = await this.actualizaCategoria(categoriaUpdate);
      if (result.res) {
        //actualizo la url de la imagen que se actualizo
        if (result.urlImage) item.urlImage = result.urlImage;
        item.imagen = null;
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "success",
          timerProgressBar: true,
          title: "Se actualizó correctamente",
        });
      } else {
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
          timerProgressBar: true,
          title: result.error,
        });
      }
      this.loading = false;
    },
    closeDeleteModalCategoria() {
      this.dialogDelete = false;
      this.nameDeleteCategoria = "";
      this.itemEliminarCategoria = {};
    },
    async openEliminarCategoria(nombreCategoria, idCategoria, urlImage) {
      this.nameDeleteCategoria = nombreCategoria;
      this.dialogDelete = true;
      this.itemEliminarCategoria = {
        idCategoria: idCategoria,
        idRestaurante: this.idRestaurant,
        urlImage: urlImage,
      };
    },
    async deleteCategoria() {
      this.loadingDelete = true;
      const result = await this.eliminaCategoria(this.itemEliminarCategoria);
      if (result.res) {
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "success",
          timerProgressBar: true,
          title: "Se eliminó correctamente"
        });
      } else {
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
          timerProgressBar: true,
          title: result.error
        });
      }
      this.closeDeleteModalCategoria();
      this.loadingDelete = false;
    },
    openDialogEstatus(item) {
      item.dialog = true;
      this.nameEstatus = item.estatus ? "activar" : "desactivar";
    },
    cancelarActualizar(item) {
      item.dialog = false;
      item.estatus = !item.estatus;
    },
    async confirmSwitchCategoria(item) {
      let categoriaObject = {
        idCategoria: item.id,
        idRestaurante: this.idRestaurant,
        estatus: item.estatus,
      };
      let respuesta = item.estatus ? "activo" : "desactivó";
      this.loadingSwitch = true;
      const resultSwitch = await this.desactivarCategoria(categoriaObject);
      if (resultSwitch.res) {
        item.dialog = false;
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "success",
          timerProgressBar: true,
          title: "Se " + respuesta + " correctamente.",
        });
      } else {
        item.dialog = false;
        item.estatus = !item.estatus;
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
          timerProgressBar: true,
          title: result.error,
        });
      }
      this.loadingSwitch = false;
    },
  },
};
</script>
