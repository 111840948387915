<template>
  <div class="container-agregar">
    <a
      href="#"
      @click.prevent="mostrarModalAgregar()"
      class="btn-add white--text"
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="25.8" height="25.8">
        <g data-name="Grupo 51" transform="translate(-291.861 4.825)">
          <circle
            data-name="Elipse 15"
            class="color-add"
            cx="12.15"
            cy="12.15"
            r="12.15"
            transform="translate(292.611 -4.075)"
          />
          <path
            data-name="Línea 22"
            class="color-add"
            transform="translate(304.761 1.765)"
            d="M0 0v12.619"
          />
          <path
            data-name="Línea 23"
            class="color-add"
            transform="translate(298.451 8.075)"
            d="M0 0h12.619"
          />
        </g>
      </svg>
      Crear nueva categoría
    </a>
    <!-- modal agregarcategoria -->
    <v-dialog v-model="dialogAgregarCategoria" persistent max-width="500px">
      <v-form
        ref="formCategory"
        v-model="validAgregarCategoria"
        lazy-validation
        @submit.prevent="saveCategory()"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5">Nueva categoría</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    color="primary"
                    class="mb-4"
                    v-model="categoria.title"
                    :rules="nameRules"
                    label="Titulo"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <div
                    class="drag-inputFile mb-8"
                    @drop.stop.prevent="onDropImgCategory($event)"
                    @dragover.prevent
                    @dragenter.prevent
                  >
                    <label for="file-img-categoria" class="select"></label>
                    <input
                      @change="changeImgCategory($event)"
                      id="file-img-categoria"
                      class="input-file"
                      accept=".png, .jpg"
                      type="file"
                    />
                    <div
                      class="img-preview"
                      v-if="urlImgCategory"
                      v-bind:style="{
                        'background-image': 'url(' + urlImgCategory + ')',
                      }"
                    ></div>
                    <v-icon small class="editBtn" v-if="urlImgCategory"
                      >mdi-pencil</v-icon
                    >
                    <v-icon
                      small
                      class="deleteBtn"
                      @click="removeImgCategory()"
                      v-if="urlImgCategory"
                      >mdi-delete</v-icon
                    >
                    <div class="upload">
                      <img
                        class="icon-camara"
                        :src="require('../assets/camara.svg')"
                        alt="icon camara"
                      />
                      <p class="label-black">Seleccionar archivo</p>
                      <p class="label-instruccion">
                        o arrastra una imagen aquí.
                      </p>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <p class="primary--text font-weight-bold">Color de la caja</p>
                  <v-color-picker
                    dot-size="20"
                    hide-mode-switch
                    mode="hexa"
                    swatches-max-height="200"
                    v-model="categoria.colorCaja"
                  ></v-color-picker>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <p class="primary--text font-weight-bold">Color del texto</p>
                  <v-color-picker
                    dot-size="20"
                    hide-mode-switch
                    mode="hexa"
                    swatches-max-height="200"
                    v-model="categoria.color"
                  ></v-color-picker>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-textarea
                    label="Comentarios"
                    no-resize
                    rows="4"
                    :value="categoria.comentarios"
                    v-model="categoria.comentarios"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="errorColor" text @click="accionClose()"
              >Cancelar</v-btn
            >
            <v-btn
              color="primary"
              type="submit"
              :disabled="!validAgregarCategoria"
              class="mr-4"
              text
              :loading="loading"
              >Guardar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <!-- modal editar -->
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      dialogAgregarCategoria: false,
      validAgregarCategoria: false,
      categoria: {
        title: "",
        imagen: null,
        colorCaja: "#FFFFFF00",
        color: "",
        comentarios: ""
      },
      nameRules: [(v) => !!v || "El título es requerido"],
      loading: false,
      urlImgCategory: "",
    };
  },
  computed: {
    ...mapState(["restaurantCurrent", "categorias"]),
  },
  methods: {
    ...mapActions(["creaCategoria"]),
    mostrarModalAgregar() {
      this.dialogAgregarCategoria = true;
      this.urlImgCategory = "";
      this.categoria.imagen = null;
    },
    accionClose() {
      this.dialogAgregarCategoria = false;
      this.categoria.imagen = null;
      this.reset();
    },
    onDropImgCategory(event) {
      const file = event.dataTransfer.files[0];
      this.processFileCategoria(file);
    },
    changeImgCategory(event) {
      const file = event.target.files[0];
      this.processFileCategoria(file);
    },
    processFileCategoria(file) {
      var ext = file.name.split(".").pop();
      if (ext == "png" || ext == "jpg") {
        if (file.size <= 512017) {
          if (file) {
            this.categoria.imagen = file;
            this.imageProcessCategory(file);
          }
        } else {
          this.$swal({
            showConfirmButton: false,
            timer: 3000,
            icon: "error",
            timerProgressBar: true,
            title: "El tamaño maximo permitido es de 500kb",
          });
        }
      } else {
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
          timerProgressBar: true,
          title: "Solo se permiten archivos de tipo .png, .jpg",
        });
      }
    },
    imageProcessCategory(file) {
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          this.urlImgCategory = e.target.result;
        };
      } else {
        this.urlImgCategory = require("../assets/default.png");
      }
    },
    removeImgCategory() {
      this.categoria.imagen = null;
      this.urlImgCategory = "";
    },
    async saveCategory() {
      //se validan los campos
      if (!this.$refs.formCategory.validate()) return;
      this.categoria.order = this.categorias.length;
      this.categoria.idRestaurante = this.restaurantCurrent.id;
      this.loading = true;
      const result = await this.creaCategoria(this.categoria);
      if (result.res) {
        this.accionClose();
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "success",
          timerProgressBar: true,
          title: "Se agregó correctamente",
        });
        this.reset();
      } else {
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
          timerProgressBar: true,
          title: result.error,
        });
      }
      this.loading = false;
    },
    reset() {
      this.$refs.formCategory.reset();
    },
  },
};
</script>