<template>
  <v-form @submit.prevent="guardarOrder()" class="mb-12">
    <div class="order-parent mb-12">
      <draggable v-model="listCategoria">
        <div v-for="categoria in listCategoria" :key="categoria.id" class="order-item">
          <img :src="require('../assets/move-icon.svg')" alt="icon move" class="icon-move" />
          {{categoria.title}}
        </div>
      </draggable>
    </div>
    <v-btn
      color="buttonPrimary"
      class="white--text pr-8 pl-8 btn-margin mr-4"
      type="submit"
      :loading="loading"
    >Guardar cambios</v-btn>
    <v-btn
      color="errorColor"
      @click="cancelarOrder()"
      link
      class="white--text pr-8 pl-8 btn-margin"
    >Cancelar</v-btn>
  </v-form>
</template>
<script>
import { mapActions, mapState } from "vuex";
import draggable from "vuedraggable";
export default {
  data() {
    return {
      loading: false,
      listChange: []
    };
  },
  components: {
    draggable
  },
  computed: {
    ...mapState(["restaurantCurrent", "listCategoriaChange"]),
    listCategoria: {
      get() {
        return this.$store.state.categorias;
      },
      set(value) {
        let itemsChange = [];
        var items = value.map(function(item, index) {
          const newSort = index;
          if (item.order !== newSort) {
            item.order = newSort;
            itemsChange.push(item);
          }
          return item;
        });
        this.$store.commit("setCategoriaChange", itemsChange);
        this.$store.commit("setCategorias", items);
      }
    }
  },
  methods: {
    ...mapActions(["guardarOrderCategorias", "obtieneCategorias"]),
    async guardarOrder() {
      const result = await this.guardarOrderCategorias();
      if (result.res) {
        this.$emit("cerrar-reordenar");
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "success",
          timerProgressBar: true,
          title: "Se guardo correctamente"
        });
      } else {
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
          timerProgressBar: true,
          title: result.error
        });
      }
    },
    async cancelarOrder() {
      this.$emit("cerrar-reordenar");
      const result = await this.obtieneCategorias(this.restaurantCurrent.id);
      if(!result.res){
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
          timerProgressBar: true,
          title: result.error
        });
      }
    }
  }
};
</script>
<style lang="scss">
.order-parent {
  border-radius: 4px;
  width: 100%;
}
.order-item {
  background-color: #3f51b5;
  border-color: #3f51b5;
  align-items: center;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  display: flex;
  font-size: 0.9375rem;
  line-height: 1;
  min-height: 48px;
  outline: none;
  padding: 22px 24px;
  position: relative;
  transition: 0.3s min-height cubic-bezier(0.25, 0.8, 0.5, 1);
  width: 100%;
  color: #ffffff;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  cursor: move;
  .icon-move {
    width: 20px;
    margin-right: 25px;
  }
  &:not(:last-child) {
    border-bottom: 1px solid #ffffff;
  }
}
</style>