<template>
  <v-container>
    <div class="container-global">
      <ActualizarRestaurante></ActualizarRestaurante>
      <v-divider class="mb-12"></v-divider>
      <CategoriasMenu></CategoriasMenu>
      <v-divider class="mb-12"></v-divider>
      <TablaPlatillos></TablaPlatillos>
    </div>
  </v-container>
</template>
<script>
import { mapActions, mapState } from "vuex";
import ActualizarRestaurante from "@/components/ActualizarRestaurante";
import CategoriasMenu from "@/components/restaurante/CategoriasMenu";
import TablaPlatillos from "@/components/restaurante/TablaPlatillos";
export default {
  data() {
    return {
      id: this.$route.params.id,
      mostrarCategoria: false
    };
  },
  computed: {
    ...mapState(["categorias"])
  },
  components: {
    ActualizarRestaurante,
    CategoriasMenu,
    TablaPlatillos
  },
  created() {
    this.initDataRestaurante();
  },
  methods: {
    ...mapActions([
      "obtieneDetalleRestaurante",
      "obtieneCategorias",
      "obtienePlatillos",
      "obtieneSubCategoriasRestaurante"
    ]),
    async initDataRestaurante() {
      await this.obtieneDetalleRestaurante(this.id);
      await this.obtieneCategorias(this.id);
      await this.obtienePlatillos(this.id);
      await this.obtieneSubCategoriasRestaurante(this.id);
    },
    ocultarAgregar() {
      this.mostrarCategoria = false;
    }
  }
};
</script>