<template>
  <v-row>
    <v-col col="12" sm="12" md="6">
      <v-form
        class="pl-15"
        ref="formSub"
        @submit.prevent="agregarSubcategorias()"
        v-model="valid"
        lazy-validation
      >
        <div class="mb-4">
          <p class="primary--text font-weight-bold">Agregar subcategoría</p>
          <v-text-field
            color="primary"
            class="mb-4"
            :rules="nameRules"
            v-model="titleName"
            label="Titulo"
            required
          ></v-text-field>
          <v-text-field
            v-model="orderSub"
            color="primary"
            label="Orden"
            required
          ></v-text-field>
          <button
            type="submit"
            :disabled="!valid"
            class="btn-add white--text mb-6"
            v-bind:class="loading ? 'loading' : ''"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="25.8" height="25.8">
              <g data-name="Grupo 51" transform="translate(-291.861 4.825)">
                <circle
                  data-name="Elipse 15"
                  class="color-add"
                  cx="12.15"
                  cy="12.15"
                  r="12.15"
                  transform="translate(292.611 -4.075)"
                />
                <path
                  data-name="Línea 22"
                  class="color-add"
                  transform="translate(304.761 1.765)"
                  d="M0 0v12.619"
                />
                <path
                  data-name="Línea 23"
                  class="color-add"
                  transform="translate(298.451 8.075)"
                  d="M0 0h12.619"
                />
              </g>
            </svg>
            Crear subcategoría
          </button>
        </div>
      </v-form>
      <div v-if="subcategoriasData.length > 0">
        <p class="primary--text font-weight-bold mb-2">Subcategorias</p>
        <v-list two-line class="mb-10">
          <v-list-item
            v-for="(item, index) in subcategoriasData"
            :key="index"
            class="headerTable"
          >
            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <div class="d-flex">
                <button
                  type="button"
                  @click="editSubcategoria(item)"
                  class="mr-4"
                >
                  <img
                    width="18px"
                    :src="require('../assets/icon-edit.svg')"
                    alt="icon edit"
                  />
                </button>
                <button
                  type="button"
                  @click="openEliminarSub(item)"
                  class="mr-4"
                >
                  <img
                    width="15.8px"
                    :src="require('../assets/icon-delete.svg')"
                    alt="icon delete"
                  />
                </button>
                <v-switch
                  color="secondary"
                  v-model="item.estatus"
                  v-on:change="openDialogEstatus(item)"
                ></v-switch>
              </div>
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <!-- modal estatus categoria -->
        <v-row justify="center">
          <v-dialog v-model="dialogEstatus" persistent max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Estas seguro que deseas {{ nameEstatus }}
                {{ itemEstatus.title }}?</v-card-title
              >
              <v-card-actions>
                <v-btn
                  color="errorColor"
                  text
                  @click="closeUpdateSwitch(itemEstatus)"
                  >Cancelar</v-btn
                >
                <v-btn
                  color="primary"
                  text
                  @click="confirmSwitchCategoria(itemEstatus)"
                  :loading="loadingSwitch"
                  >Confirmar</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
        <!-- modal estatus categoria -->
        <!-- modal editar -->
        <v-row justify="center">
          <v-dialog v-model="dialogEdit" persistent max-width="500px">
            <v-form v-model="validEditar" lazy-validation>
              <v-card>
                <v-card-title>
                  <span class="text-h5">Editar</span>
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        label="Título"
                        color="primary"
                        :rules="nameRules"
                        v-model="itemParam.title"
                        required
                      ></v-text-field>
                      <v-text-field
                        label="Orden"
                        color="primary"
                        v-model="itemParam.order"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="errorColor" text @click="dialogEdit = false"
                    >Cancelar</v-btn
                  >
                  <v-btn
                    color="primary"
                    text
                    @click="updateSubcategoria()"
                    :loading="loadingEditar"
                    :disabled="!validEditar"
                    >Guardar cambios</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-form>
          </v-dialog>
        </v-row>
        <!-- modal editar -->
        <!-- modal eliminar -->
        <v-row justify="center">
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Estas seguro que deseas eliminar
                {{ nameDelete }}?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="errorColor" text @click="closeDeleteSub"
                  >Cancelar</v-btn
                >
                <v-btn
                  color="primary"
                  text
                  @click="eliminarSubcategoria()"
                  :loading="loadingDelete"
                  >Confirmar</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
        <!-- modal eliminar -->
      </div>
    </v-col>
  </v-row>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  props: {
    idCategoria: String,
    idRest: String,
  },
  data() {
    return {
      nameRules: [(v) => !!v || "El título es requerido"],
      titleName: "",
      orderSub: 0,
      loading: false,
      valid: false,
      validEditar: false,
      subcategoriasData: [],
      dialogEdit: false,
      dialogDelete: false,
      dialogEstatus: false,
      itemParam: {},
      itemDelete: {},
      itemEstatus: {},
      nameDelete: "",
      loadingEditar: false,
      loadingDelete: false,
      loadingSwitch: false,
      nameEstatus: "",
    };
  },
  computed: {
    ...mapState(["subcategorias"]),
  },
  mounted() {
    this.subcategoriasData = this.subcategorias.filter(
      (item) => item.idCategoria == this.idCategoria
    );
  },
  methods: {
    ...mapActions([
      "creaSubCategoria",
      "actualizaSubCategoria",
      "desactivarSubCategoria",
      "eliminaSubcategoria",
    ]),
    async agregarSubcategorias() {
      //se validan los campos
      if (!this.$refs.formSub.validate()) return;
      this.loading = true;
      let subcategoria = {
        idCategoria: this.idCategoria,
        title: this.titleName,
        order: this.orderSub,
      };
      const result = await this.creaSubCategoria(subcategoria);
      if (result.res) {
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "success",
          timerProgressBar: true,
          title: "Se agregó correctamente",
        });
        this.reset();
        this.subcategoriasData.push(result.data);
      } else {
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
          timerProgressBar: true,
          title: result.error,
        });
      }
      this.loading = false;
    },
    editSubcategoria(item) {
      this.dialogEdit = true;
      this.itemParam = item;
      this.itemParam.idRestaurante = this.idRest;
    },
    async updateSubcategoria() {
      this.loadingEditar = true;
      const result = await this.actualizaSubCategoria(this.itemParam);
      if (result.res) {
        let itemUpdate = {
          id: this.itemParam.id,
          title: this.itemParam.title,
          estatus: true,
          idCategoria: this.itemParam.idCategoria,
          dialog: false,
          order: this.itemParam.order
        };
        //actualizo mi informacion clonada del vuex
        this.subcategoriasData = this.subcategoriasData.map((item) =>
          item.id === itemUpdate.id ? itemUpdate : item
        );
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "success",
          timerProgressBar: true,
          title: "Se actualizó correctamente",
        });
      } else {
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
          timerProgressBar: true,
          title: result.error,
        });
      }
      this.closeEditar();
      this.loadingEditar = false;
    },
    async openEliminarSub(item) {
      this.dialogDelete = true;
      this.itemDelete = {
        idSub: item.id,
        idRestaurante: this.idRest,
      };
      this.nameDelete = item.title;
    },
    async eliminarSubcategoria() {
      this.loadingDelete = true;
      const result = await this.eliminaSubcategoria(this.itemDelete);
      if (result.res) {
        this.subcategoriasData = this.subcategoriasData.filter(
          (item) => item.id !== this.itemDelete.idSub
        );
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "success",
          timerProgressBar: true,
          title: "Se eliminó correctamente",
        });
      } else {
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
          timerProgressBar: true,
          title: result.error,
        });
      }
      this.loadingDelete = false;
      this.closeDeleteSub();
    },
    openDialogEstatus(item) {
      this.dialogEstatus = true;
      this.nameEstatus = item.estatus ? "activar" : "desactivar";
      this.itemEstatus = item;
    },
    closeUpdateSwitch(item) {
      this.dialogEstatus = false;
      item.estatus = !item.estatus;
      this.itemEstatus = {};
    },
    async confirmSwitchCategoria(item) {
      this.loadingSwitch = true;
      let subcategoriaObject = {
        idSub: item.id,
        idRestaurante: this.idRest,
        estatus: item.estatus,
      };
      let respuesta = item.estatus ? "activo" : "desactivó";
      const result = await this.desactivarSubCategoria(subcategoriaObject);
      if (result.res) {
        this.dialogEstatus = false;
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "success",
          timerProgressBar: true,
          title: "Se " + respuesta + " correctamente.",
        });
      } else {
        this.dialogEstatus = false;
        item.estatus = !item.estatus;
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
          timerProgressBar: true,
          title: result.error,
        });
      }
      this.loadingSwitch = false;
    },
    closeDeleteSub() {
      this.dialogDelete = false;
      this.nameDelete = "";
      this.itemDelete = {};
    },
    closeEditar() {
      this.dialogEdit = false;
      this.itemParam = {};
    },
    reset() {
      this.$refs.formSub.reset();
    },
  },
};
</script>