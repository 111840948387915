<template>
  <div class="container-categorias">
    <h2 class="mb-6">Contenidos menú</h2>
    <div class="container-head">
      <h3 class="title-head">Categorías</h3>
      <p class="float-paso">Paso 2 de 3</p>
    </div>
    <AgregarCategoria></AgregarCategoria>
    <div class="d-flex justify-end mb-4">
      <a href="#" @click.prevent="mostrarReordenar()" class="btn-add accent white--text">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 0 24 24"
          width="24px"
          fill="#ffffff"
        >
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path d="M3 15h18v-2H3v2zm0 4h18v-2H3v2zm0-8h18V9H3v2zm0-6v2h18V5H3z" />
        </svg>
        Re-ordernar
      </a>
    </div>
    <ListCategorias
      :categorias="categorias"
      :idRestaurant="restaurantCurrent.id"
      v-show="!editOrder"
    ></ListCategorias>
    <OrderCategoria
      v-on:cerrar-reordenar="cerrarReordenar()"
      :categorias="categorias"
      v-show="editOrder"
    ></OrderCategoria>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import AgregarCategoria from "@/components/AgregarCategoria";
import ListCategorias from "@/components/ListCategorias";
import OrderCategoria from "@/components/OrderCategoria";
export default {
  data() {
    return {
      editOrder: false
    };
  },
  components: {
    AgregarCategoria,
    ListCategorias,
    OrderCategoria
  },
  computed: {
    ...mapState(["restaurantCurrent", "categorias"])
  },
  methods: {
    mostrarReordenar() {
      this.editOrder = true;
    },
    cerrarReordenar() {
      this.editOrder = false;
    }
  }
};
</script>
<style lang="scss">
.container-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  .title-head {
    margin-bottom: 0;
  }
  .float-paso {
    color: #a7a7a7;
    margin-bottom: 0;
    font-weight: normal;
  }
}
</style>